// eslint-disable-next-line import/no-extraneous-dependencies
import { ContainerRect } from 'yet-another-react-lightbox';
import { Slide } from './Lightbox.types';
/**
 * Allows for rendering custom media types, such as youtube and vimeo links and pdf documents
 * @param slide The slide to render
 * @param rect The container size
 */

function renderSlide(slide: Slide, rect: ContainerRect) {
  const height =
    slide.height ?
      Math.min(
        slide.height,
        rect.height,
        slide.width ? (slide.height * rect.width) / slide.width : 999999999,
      )
    : rect.height;

  const width =
    slide.width ?
      Math.min(
        slide.width,
        rect.width,
        slide.height ? (slide.width * rect.height) / slide.height : 999999999,
      )
    : rect.width;

  switch (slide?.type) {
    case 'youTube': {
      return (
        <iframe
          height={height}
          width={width}
          src={slide.src}
          title={slide.title || undefined}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      );
    }
    case 'vimeo': {
      return (
        <iframe
          height={height}
          width={width}
          src={slide.src}
          title={slide.title || undefined}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      );
    }
    case 'document':
    case 'image':
    default: {
      return null;
    }
  }
}

export default renderSlide;
