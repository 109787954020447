import { useEffect, useState } from 'react';
import { FetchResult, useLazyQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import client from '../services/client';
import { profile as profileQuery } from '../graphql/queries';
import {
  createProfile as createProfileMutation,
  updateProfile,
  setProfileFrequentlyAskedQuestions,
  setProfileContacts,
  setProfileTradingHours,
  setProfilePaymentTypes,
  setProfileAmenities,
  setProfileProducts,
  setProfileLicences,
  setProfileMedia,
  setProfileSettings,
  setProfileCategories,
} from '../graphql/mutations';
import { Profile as ProfileType } from '../__generated__/graphql';

interface ProfileQuery {
  __typename?: 'Query';
  profile: ProfileType;
}

const parseResponses = (responses: any[]) =>
  responses.reduce((acc, { data }) => {
    const mutation = Object.keys(data)[0];
    if (!mutation) return acc;

    const mutationData = data[mutation];
    const key = Object.keys(mutationData)[0];
    if (!key) return acc;

    return { ...acc, [key]: data[mutation][key] };
  }, {});

const useProfile = () => {
  const [updateLoading, setUpdateLoading] = useState<boolean>();
  const [updateError, setUpdateError] = useState<unknown>();

  const { profileId: id } = useParams();

  const [get, { data: profileQueryData, loading, error }] = useLazyQuery<ProfileQuery>(
    profileQuery,
    {
      variables: {
        id,
      },
      errorPolicy: 'all',
    },
  );

  const { profile } = profileQueryData || {};

  useEffect(() => {
    if (id) {
      get();
    }
  }, [id]);

  const [createProfile, { loading: createLoading, error: createError }] =
    useMutation(createProfileMutation);

  const clearUpdateError = () => {
    setUpdateError(undefined);
  };

  const writeToCache = (data: any) => {
    const { profile: profileUpdates = {}, ...otherUpdates } = data;

    if (!id) {
      return;
    }

    client.writeQuery({
      query: profileQuery,
      data: {
        profile: {
          ...profile,
          ...profileUpdates,
          ...otherUpdates,
        },
      },
      variables: { id },
    });
  };

  const update = async (
    inputs: {
      [x: string]: any;
      frequentlyAskedQuestions: any;
      contacts: any;
      tradingHours: any;
      paymentTypes: any;
      amenities: any;
      products: any;
      licences: any;
      media: any;
      settings: any;
      categories: any;
    },
    profileId = id,
    updateCache = true,
  ) => {
    if (!profileId && !inputs['id']) {
      return;
    }
    const {
      frequentlyAskedQuestions,
      contacts,
      tradingHours,
      paymentTypes,
      amenities,
      products,
      licences,
      media,
      settings,
      categories,
      ...updateProfileInput
    } = inputs;

    const mutations = [
      {
        frequentlyAskedQuestions,
        mutation: setProfileFrequentlyAskedQuestions,
      },
      { contacts, mutation: setProfileContacts },
      { tradingHours, mutation: setProfileTradingHours },
      { paymentTypes, mutation: setProfilePaymentTypes },
      { amenities, mutation: setProfileAmenities },
      { products, mutation: setProfileProducts },
      { licences, mutation: setProfileLicences },
      { media, mutation: setProfileMedia },
      { settings, mutation: setProfileSettings },
      { categories, mutation: setProfileCategories },
    ];

    const updates = [];

    if (Object.keys(updateProfileInput).length) {
      updates.push(() =>
        client.mutate({
          mutation: updateProfile,
          variables: {
            input: { id: profileId, ...updateProfileInput },
          },
        }),
      );
    }

    mutations.forEach(({ mutation, ...input }) => {
      const key = Object.keys(input)[0];
      if (key && input[key as keyof typeof input]) {
        updates.push(() =>
          client.mutate({
            mutation,
            variables: {
              input: { profileId, [key]: input[key as keyof typeof input] },
            },
          }),
        );
      }
    });

    setUpdateLoading(true);

    try {
      // updates need to run sequentially
      const responses = [];
      const first = updates.shift() as () => Promise<FetchResult<any>>;

      const last = updates.reduce(async (promise, updateFn) => {
        responses.push(await promise);
        return updateFn();
      }, first());

      responses.push(await last);

      if (updateCache) {
        writeToCache(parseResponses(responses));
      }
    } catch (e) {
      setUpdateError(e);
      throw e;
    } finally {
      setUpdateLoading(false);
    }
  };

  const create = async (input: any) => {
    const { data } = await createProfile({ variables: { input } });
    return data.createProfile.profile;
  };

  return {
    profile,
    writeToCache,
    loading,
    error,
    update: {
      update,
      loading: updateLoading,
      error: updateError,
      clearError: clearUpdateError,
    },
    create: {
      create,
      loading: createLoading,
      error: createError,
    },
  };
};

export default useProfile;
export type Profile = ReturnType<typeof useProfile>;
